//Alpine.js for the heavy lifting
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist'

import Swiper from 'swiper/bundle';

import isExternal from './modules/ExternalAlerts';
import {Search} from './modules/search';
import {HelpSearch} from './helpSearch';
import * as Ladda from 'ladda';
import twemoji from 'twemoji';
import "@lottiefiles/lottie-player";

//Local script for nav
import Nav from  './nav';

//Video player styling
import Plyr from 'plyr';

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);

window.Ladda = Ladda;
window.Swiper = Swiper;
document.addEventListener('alpine:init', () => {
    Alpine.data('nav', Nav); 

    Alpine.directive('breakpoint', (el, { value, modifiers, expression }, { Alpine, evaluateLater, effect, cleanup }) => {  
        let mql = window.matchMedia(`(${value}-width: ${modifiers[0]}px)`);   
        
        // for initial value
        el.dispatchEvent(new CustomEvent('breakpoint', {
            detail: { matches: mql.matches },
            bubbles: true,
        })); 
            
        mql.addEventListener('change', e => {
            console.log(e)
            el.dispatchEvent(new CustomEvent('breakpoint', {
                detail: { matches: e.matches },
                bubbles: false,
            }));
        });
        
        cleanup(() => {
            mql.removeEventListener('change');
        });
    });

    Alpine.directive('plyr', (el, { value, modifiers, expression }, { Alpine, evaluate, effect, cleanup }) => {
        // console.log('x-plyr'); 
        const player = new Plyr(el, expression ? evaluate(expression): {});
        console.log(player);
    });

    Alpine.directive('ladda', (el, { value, modifiers, expression }, { Alpine, evaluate, effect, cleanup, evaluateLater }) => {
        // console.log('x-ladda');
        var l = Ladda.create(el);
        // expression = l;
        let getThingToLog = evaluateLater(expression);
        effect(() => {
            getThingToLog(thingToLog => {
                console.log(thingToLog)
            })
        })
    });

    Alpine.directive('emoji', (el, { value, modifiers, expression }, { Alpine, evaluate, effect, cleanup, evaluateLater }) => {

        const replaceEmojisWithImages = function (text) {
            // Use twemoji to replace emojis with corresponding image tags
            const options = {
                folder: 'svg',
                ext: '.svg',
            };
            const replacedText = twemoji.parse(text, options);
            // console.log(replacedText);

            // Replace image tags with Noto color emoji images
            const imageRegex = /<img class="emoji" draggable="false" alt="([^"]+)" src="([^"]+)"\/>/g;
            // const notoBaseUrl = 'https://noto-website-2.storage.googleapis.com/emoji/emoji_u';

            const notoExtension = '.gif';
            const notoReplacedText = replacedText.replace(imageRegex, (match, p1, p2) => {
                const codePoint = p1.codePointAt(0).toString(16);
                // const notoUrl = `/${codePoint}${notoExtension}`;
                return `<lottie-player autoplay loop count="4" hover mode="normal" src="/assets/dist/images/emoji/${codePoint}.json"  class="emoji-glyph" ></lottie-player>`
                //    `<img class="emoji" draggable="false" alt="${p1}" src="${notoUrl}">`;
            });
            // console.log(notoReplacedText);
            return notoReplacedText;
        }
        
        el.innerHTML = replaceEmojisWithImages(el.innerHTML);


    });

    Alpine.magic('isExternal', () => element => {
        return isExternal(element);
    })

    // Alpine.data('AccountFinder', AccountFinder);
    // Alpine.data('ProductSlider', ProductSlider);

    Alpine.data('Search', (options) => {console.log(options); return Search(options) });
    Alpine.data('HelpSearch', HelpSearch);

});